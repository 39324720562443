import { h } from 'preact';

const SearchIcon = ({ className }) => {
  return (
    <svg className={className} width='14' height='14' viewBox='0 0 14 14' fill='none'
         xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.6 1.4C3.2804 1.4 1.4 3.2804 1.4 5.6C1.4 7.9196 3.2804 9.8 5.6 9.8C7.9196 9.8 9.8 7.9196 9.8 5.6C9.8 3.2804 7.9196 1.4 5.6 1.4ZM0 5.6C0 2.50721 2.50721 0 5.6 0C8.69279 0 11.2 2.50721 11.2 5.6C11.2 6.8941 10.761 8.08567 10.0239 9.03395L13.795 12.805C14.0683 13.0784 14.0683 13.5216 13.795 13.795C13.5216 14.0683 13.0784 14.0683 12.805 13.795L9.03395 10.0239C8.08567 10.761 6.8941 11.2 5.6 11.2C2.50721 11.2 0 8.69279 0 5.6Z'
        fill='#212121' />
    </svg>
  );
};

export default SearchIcon;
