import { FunctionComponent, h } from 'preact';
import Loader from 'assets/images/FullLoader.png';
import styles from './BanksPending.module.scss';
import Button from 'shared/ui/Button';
import { useState } from 'preact/hooks';

const BanksPending: FunctionComponent<any> = () => {
  const [isVisible, setVisibility] = useState(true);

  if (!isVisible) {
    return <></>
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img alt='Загрузка' className={styles.icon} src={Loader} />
        <p className={styles.title}>Ждем подтверждение от банка</p>
        <p className={styles.text}>Если вы оплатили счёт в банке, то совсем скоро мы это увидим, и чек будет закрыт</p>
        <Button
          className={styles.button}
          onClick={() => setVisibility(false)}
          text={<p className={styles.buttonText}>Выбрать другой банк</p>}
        />
      </div>
    </div>
  );
};

export default BanksPending;
