import { h } from 'preact';
import CookieConsent from 'react-cookie-consent';
import styles from './Cookie.module.scss';

const Cookie = () => {
  return (
    <>
    {/* @ts-ignore: Unreachable code error */}
    <CookieConsent
      containerClasses={styles.container}
      contentClasses={styles.content}
      buttonClasses={styles.button}
      buttonText='Хорошо'
      disableStyles={true}
      expires={400}
      location='none'
    >
      <p className={styles.text}>Мы используем файлы Сookie</p>
      <a className={styles.link} href='https://coffeemania.ru/policy' target='_blank'>Узнать больше</a>
    </CookieConsent>
    </>
  );
};

export default Cookie;
