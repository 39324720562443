import { sample } from 'effector';
import { $id, getLocationFx, IndexGate, onGetLocation } from '.';
import { logoutFx } from 'models/User';

sample({
  clock: [IndexGate.open],
  target: $id,
});

sample({
  clock: [$id, onGetLocation, logoutFx.doneData],
  source: $id,
  filter: (id) => !!id,
  target: [getLocationFx],
});
