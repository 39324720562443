import { h } from 'preact';

const TelegramIcon = () => {
  return (
    <svg width="29" height="23" viewBox="0 0 25 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.1732 0.269941L0.889565 8.03996C0.699273 8.04745 0.5198 8.1289 0.390625 8.26642C0.261451 8.40394 0.193158 8.58625 0.20077 8.77325C0.208381 8.96025 0.291274 9.13662 0.431212 9.26356C0.571151 9.3905 0.756673 9.45761 0.946965 9.45013L6.09858 10.9449L8.02864 16.9451C8.07288 17.0891 8.15475 17.2192 8.26607 17.3223C8.37739 17.4254 8.51428 17.4981 8.66309 17.5331C8.81191 17.568 8.96745 17.5641 9.11419 17.5215C9.26095 17.4789 9.3938 17.3994 9.4995 17.2907L12.1686 14.6184L17.3991 18.3905C17.5461 18.4972 17.7179 18.5658 17.8988 18.5901C18.0798 18.6144 18.2641 18.5937 18.4348 18.5299C18.6055 18.466 18.7571 18.361 18.8757 18.2245C18.9944 18.0882 19.0763 17.9247 19.114 17.7489L22.5795 1.43333C22.6167 1.25223 22.6037 1.06462 22.5416 0.890179C22.4797 0.715739 22.3711 0.560903 22.2272 0.441913C22.0834 0.322925 21.9097 0.24417 21.7242 0.213912C21.5387 0.183653 21.3484 0.203006 21.1732 0.269941ZM18.6332 3.87997L9.20533 12.073C9.11089 12.1541 9.0518 12.2677 9.0403 12.3903L8.67438 15.5562C8.67438 15.5805 8.66455 15.6038 8.64706 15.6209C8.62958 15.6381 8.60584 15.6478 8.58111 15.6478C8.55637 15.6478 8.53265 15.6381 8.51515 15.6209C8.49766 15.6038 8.48783 15.5805 8.48783 15.5562L6.99545 10.8462C6.96164 10.7421 6.96449 10.6298 7.00354 10.5275C7.04259 10.4252 7.11556 10.3387 7.21069 10.2821L18.3533 3.49922C18.4046 3.47656 18.4622 3.47197 18.5165 3.48623C18.5707 3.50047 18.6183 3.53271 18.6513 3.57746C18.6842 3.62222 18.7003 3.67677 18.6971 3.73188C18.6937 3.787 18.6712 3.83931 18.6332 3.87997Z" fill="#EC4C41"/>
    </svg>
  );
};

export default TelegramIcon;
