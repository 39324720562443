import { useEffect } from 'preact/hooks';

const useAnalytics = () => {
  useEffect(() => {
    // @ts-ignore: Unreachable code error
    ym(91804168, 'hit', window.location.href);
  }, []);

  return;
};

export default useAnalytics;
