import { h } from 'preact';
import styles from './BillItem.module.scss';

const BillItem = ({ isPrice = true, price, title }) => {
  return (
    <div className={styles.container}>
      <p className={styles.title}>{title}</p>
      <p className={styles.price}>{price} {isPrice ? '₽' : ''}</p>
    </div>
  );
};

export default BillItem;
