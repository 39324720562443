import { h } from 'preact';
import styles from './SMSCode.module.scss';
import useSmsCode from './useSMSCode';
import CloseIcon from 'assets/icons/CloseIcon';
import { useGate, useUnit } from 'effector-react';
import { $phone, setConfirmPopupVisibility } from 'models/Order';
import {
  $codeVerification,
  $isCallVerificationMode, $isWorkingSendTimeout,
  $verificationTimeout,
  onCallCode,
  onSendCode,
  SMSCodeGate,
} from 'models/User';

const SMSCode = () => {
  useGate(SMSCodeGate);
  const { code, codeRef, handleKeyUp, onInputCode, selectedIndex, setCode } = useSmsCode();
  const phone = useUnit($phone);
  const codeVerification = useUnit($codeVerification);
  const isCallVerificationMode = useUnit($isCallVerificationMode);
  const verificationTimeout = useUnit($verificationTimeout);
  const CODE_LENGTH = new Array(4).fill(0);
  const description = isCallVerificationMode ? `Сейчас вам поступит звонок на номер\n${phone}, отвечать на него не нужно. Укажите последние 4 цифры номера.` : `Мы отправили SMS с кодом на номер\n${phone}`;
  const title = isCallVerificationMode ? 'Код из входящего номера' : 'Код из SMS';
  const remainingTimeout = `0:${verificationTimeout.toString().padStart(2, '0')}`;
  const isErrorCodeVerification = codeVerification?.success === false;
  const isWorkingSendTimeout = useUnit($isWorkingSendTimeout);

  const handleSendCode = () => {
    onSendCode();
    setCode(['', '', '', '']);
  };

  const handleCallCode = () => {
    onCallCode();
    setCode(['', '', '', '']);
  };

  return (
    <div className={styles.bg}>
      <div className={styles.container}>
        <h3 className={styles.title}>{title}</h3>
        <CloseIcon className={styles.closeButton} onClick={() => setConfirmPopupVisibility(false)} />
        <p className={styles.description}>{description}</p>
        <p className={styles.action} onClick={() => setConfirmPopupVisibility(false)}>Изменить номер</p>
        <div className={styles.codeBoxes}>
          {CODE_LENGTH.map((item, index) => (
            <div className={styles.codeBox}
                 style={{ borderColor: isErrorCodeVerification ? '#EC4C41' : '' }}>{code[index]}</div>
          ))}
          <input className={styles.input} inputMode='numeric' onInput={onInputCode} onKeyUp={handleKeyUp} ref={codeRef}
                 style={{ left: `${selectedIndex * 60}px` }}
                 value='' />
        </div>
        {isErrorCodeVerification && <p className={styles.error}>{codeVerification?.message}</p>}
        {isWorkingSendTimeout ? (
          <p className={styles.timer}>Повторный запрос кода через: {remainingTimeout}</p>
        ) : (
          <>
            <p className={styles.action} onClick={handleSendCode}>Отправить код ещё раз</p>
            <p className={styles.action} onClick={handleCallCode}>Позвоните мне для авторизации</p>
          </>
        )}
      </div>
    </div>
  );
};

export default SMSCode;
