import { h, FunctionComponent } from 'preact';

const CloseIcon: FunctionComponent<any> = ({ className = '', onClick = () => 1 }) => {
  return (
    <svg width='11' height='12' viewBox='0 0 11 12' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}
         onClick={onClick}>
      <path
        d='M0.389783 0.889829C0.687325 0.592286 1.16974 0.592286 1.46728 0.889829L5.49996 4.92251L9.53264 0.889829C9.83018 0.592286 10.3126 0.592286 10.6101 0.889829C10.9077 1.18737 10.9077 1.66978 10.6101 1.96732L6.57746 6.00001L10.6101 10.0327C10.9077 10.3302 10.9077 10.8126 10.6101 11.1102C10.3126 11.4077 9.83018 11.4077 9.53264 11.1102L5.49996 7.0775L1.46728 11.1102C1.16974 11.4077 0.687325 11.4077 0.389783 11.1102C0.0922404 10.8126 0.0922404 10.3302 0.389783 10.0327L4.42246 6.00001L0.389783 1.96732C0.0922404 1.66978 0.0922404 1.18737 0.389783 0.889829Z'
        fill='#212121' />
    </svg>
  );
};

export default CloseIcon;
