import { h } from 'preact';
import { memo } from 'preact/compat';
import styles from './Footer.module.scss';
import SocialNetwork from 'shared/ui/SocialNetwork';
import Button from 'shared/ui/Button';
import MessageIcon from 'assets/icons/MessageIcon';
import Popup from 'shared/ui/Popup';
import { useState } from 'preact/hooks';
import PopupFooter from './components/PopupFooter';

const Footer = () => {
  const [isOpenedBlankOrderPopup, setOpenPopup] = useState(false);

  return (
    <footer className={styles.container}>
      <div className={styles.socialNetworks}>
        <SocialNetwork name='vk' />
        <SocialNetwork name='telegram' />
      </div>
      <Button className={styles.button} icon={<MessageIcon />} onClick={() => setOpenPopup(true)}
              text='Оставить отзыв' />
      {isOpenedBlankOrderPopup &&
        <Popup content={<PopupFooter />} title='Оставить отзыв' setOpenPopup={setOpenPopup} />}
    </footer>
  );
};

export default memo(Footer);
