import { sample } from 'effector';
import {
  $billData,
  $scores,
  fetchOrder,
  getTableInfoFx, onGetTableData,
  OrderGate,
  resetData,
} from '.';
import { $locationData } from 'models/Table';
import { reset } from 'patronum';
import { $payLink } from 'models/Payment';
import { $isConfirmPopupVisible, confirmCodeFx, getScoresFx } from 'models/User';

sample({
  clock: [$locationData, fetchOrder, onGetTableData, confirmCodeFx.doneData],
  source: { locationData: $locationData },
  filter: ({ locationData }) => !!locationData?.tableUid,
  fn: ({ locationData }) => locationData?.tableUid,
  target: getTableInfoFx,
});

sample({
  clock: getScoresFx.doneData,
  filter: (data) => data?.success,
  fn: () => false,
  target: $isConfirmPopupVisible,
});

reset({
  clock: [OrderGate.close],
  target: [$billData, $payLink],
});

reset({
  clock: resetData,
  target: $billData,
});

reset({
  clock: OrderGate.open,
  target: $scores,
});
