import { sample } from 'effector';
import { $billData, $phone, $scores, fetchOrder, getTableInfoFx, resetData } from 'models/Order';
import {
  $codeVerification,
  $authData,
  $userScores,
  $verificationError,
  callCodeFx,
  checkAuthFx,
  confirmCodeFx,
  getScoresFx,
  logoutFx,
  onCallCode,
  onConfirmCode,
  onLogout,
  onSendCode,
  sendCodeFx,
  SMSCodeGate,
  startVerificationCountdown,
  $isWorkingSendTimeout,
  $isCallVerificationMode,
  $isConfirmPopupVisible,
} from '.';
import { $id, $locationData, IndexGate } from 'models/Table';
import { debounce, reset } from 'patronum';
import { authDTO, onLogoutFn, phoneGetter, phoneSetter, sendCodeDTO } from 'models/User/utils';

sample({
  clock: onSendCode,
  source: { isWorkingSendTimeout: $isWorkingSendTimeout, locationData: $locationData, phone: $phone },
  filter: ({ isWorkingSendTimeout, locationData, phone }) => !isWorkingSendTimeout && !!locationData && !!phone,
  fn: sendCodeDTO,
  target: sendCodeFx,
});

sample({
  clock: [sendCodeFx.doneData, callCodeFx.doneData],
  filter: (isSuccess) => !!isSuccess,
  fn: (isSuccess) => !!isSuccess,
  target: [$isConfirmPopupVisible, startVerificationCountdown],
});

sample({
  clock: onCallCode,
  source: { isWorkingSendTimeout: $isWorkingSendTimeout, locationData: $locationData, phone: $phone },
  filter: ({ isWorkingSendTimeout, locationData, phone }) => !isWorkingSendTimeout && !!locationData && !!phone,
  fn: sendCodeDTO,
  target: callCodeFx,
});

sample({
  clock: onConfirmCode,
  source: { locationData: $locationData },
  fn: ({ locationData }, code) => ({ locationData, code }),
  target: confirmCodeFx,
});

sample({
  clock: [getTableInfoFx.doneData, $authData],
  source: { authData: $authData, billData: $billData, locationData: $locationData },
  filter: ({ authData, billData, locationData }) => !!authData && !!locationData && !!billData,
  target: getScoresFx,
});

sample({
  clock: confirmCodeFx.doneData,
  filter: (data) => !data?.success,
  fn: (data) => data?.message,
  target: $verificationError,
});

const onSetPhone = sample({
  clock: confirmCodeFx.doneData,
  source: $phone,
  filter: (_, data) => data?.success,
  fn: phoneSetter,
  target: $authData,
});

sample({
  clock: confirmCodeFx.doneData,
  fn: (data) => !data.success,
  target: $isConfirmPopupVisible,
});

sample({
  clock: $userScores,
  source: $scores,
  filter: (scores) => scores === 0,
  fn: (_, { selectedWriteOffAmount }) => selectedWriteOffAmount,
  target: $scores,
});

sample({
  clock: [IndexGate.open, $locationData],
  source: $locationData,
  fn: authDTO,
  filter: (data) => !!data,
  target: checkAuthFx,
});

sample({
  clock: checkAuthFx.doneData,
  filter: (isAuth) => !!isAuth,
  fn: phoneGetter,
  target: $authData,
});

sample({
  clock: onLogout,
  source: $id,
  fn: onLogoutFn,
  target: logoutFx,
});

reset({
  clock: resetData,
  target: $userScores,
});

reset({
  clock: SMSCodeGate.close,
  target: [$codeVerification, $isCallVerificationMode],
});

reset({
  clock: onLogout,
  target: [$userScores, $phone, $authData],
});

reset({
  clock: onSetPhone,
  target: $phone,
});

reset({
  clock: confirmCodeFx.doneData,
  target: [$userScores, $authData],
});
