import { h } from 'preact';

const EmailIcon = () => {
  return (
    <svg width='21' height='16' viewBox='0 0 21 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.3996 10.7697C9.80747 10.7697 9.23125 10.576 8.7575 10.2179L0 3.71338V13.7932C0 14.3783 0.230614 14.9398 0.641125 15.3537C1.05196 15.7675 1.60879 16 2.18932 16H18.61C19.1905 16 19.7475 15.7675 20.1582 15.3537C20.5687 14.9396 20.7993 14.3783 20.7993 13.7932L20.7996 3.71338L12.0417 10.2294C11.5667 10.5836 10.9905 10.7733 10.3996 10.77L10.3996 10.7697Z'
        fill='#EC4C41' />
      <path
        d='M19.7049 0H1.09474C0.804412 0 0.525833 0.116227 0.32057 0.323128C0.115307 0.530028 0 0.810822 0 1.10348V1.65521L9.74863 8.89949C9.93725 9.04004 10.1654 9.11625 10.4 9.11625C10.6346 9.11625 10.8628 9.0402 11.0514 8.89949L20.8 1.65521V1.10348C20.8 0.81083 20.6847 0.530028 20.4794 0.323128C20.2742 0.116227 19.9956 0 19.7053 0H19.7049Z'
        fill='#EC4C41' />
    </svg>
  );
};

export default EmailIcon;
