import { useEffect, useRef, useState } from 'preact/hooks';
import { onConfirmCode } from 'models/User';

const useSmsCode = () => {
  const [code, setCode] = useState(['', '', '', '']);
  const [selectedIndex, setIndex] = useState(0);
  const codeRef = useRef(null);

  const fixAndroidBug = () => {
    if (document.activeElement.tagName == 'INPUT') {
      window.setTimeout(() => {
        document.activeElement.scrollIntoView();
      }, 0);
    }
  };

  useEffect(() => {
    if (codeRef.current) {
      window.addEventListener('resize', fixAndroidBug);
      codeRef.current.focus();
    }

    return () => {
      window.removeEventListener('resize', fixAndroidBug);
    };
  }, [codeRef.current]);

  const handleKeyUp = (e) => {
    if (e.key === 'Backspace') {
      let _code = [...code];
      _code[selectedIndex] = '';
      setCode(_code);
      setIndex(index => index > 0 ? index - 1 : index);
      return;
    }
  };


  const onInputCode = (e) => {
    const value = e.target.value;
    let _code = [...code];
    _code[selectedIndex] = value;
    setCode(_code);
    setIndex(index => index < 3 ? index + 1 : index);

    if (_code.every(Boolean)) {
      onConfirmCode(_code.join(''));
      setCode(['', '', '', '']);
      setIndex(0);
      return;
    }
  };

  return { code, codeRef, handleKeyUp, onInputCode, selectedIndex, setCode, setIndex };
};

export default useSmsCode;
