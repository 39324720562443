import { h } from 'preact';
import { useState } from 'preact/hooks';
import styles from './Order.module.scss';
import inputStyles from '../../shared/ui/Input/Input.module.scss';
import Divider from 'shared/ui/Divider';
import Sbp from 'assets/images/sbp.png';
import Button from 'shared/ui/Button';
import BillItem from './components/BillItem';
import ArrowRightIcon from 'assets/icons/ArrowRightIcon';
import useOrder from './useOrder';
import SMSCode from './components/SMSCode';
import { useGate, useUnit } from 'effector-react';
import { $billData, $phone, $scores, getTableInfoFx, OrderGate } from 'models/Order';
import Header from 'shared/widgets/Header';
import InputMask from 'react-input-mask';
import {
  $authData,
  $isConfirmPopupVisible, $isWorkingSendTimeout,
  $userScores,
  getScoresFx,
  onSendCode,
} from 'models/User';
import Total from './components/Total';
import OrderPopup from './components/OrderPopup';
import { getPayLinkFx } from 'models/Payment';
import { getBanksFx } from 'models/Banks';
import BillToggler from './components/BillToggler';
import useAnalytics from 'shared/hooks/useAnalytics';
import Scores from './components/Scores';
import Cookie from 'shared/ui/Cookie';
import { memo } from 'preact/compat';
import { $id } from 'models/Table';

const Order = () => {
  useAnalytics();
  useGate(OrderGate);
  const authData = useUnit($authData);
  const userScores = useUnit($userScores);
  const billData = useUnit($billData);
  const id = useUnit($id);
  const isConfirmPopupVisible = useUnit($isConfirmPopupVisible);
  const isBanksListPending = useUnit(getBanksFx.pending);
  const isPayLinkPending = useUnit(getPayLinkFx.pending);
  const isScoresPending = useUnit(getScoresFx.pending);
  const isTableInfoPending = useUnit(getTableInfoFx.pending);
  const isWorkingSendTimeout = useUnit($isWorkingSendTimeout);
  const phone = useUnit($phone);
  const scores = useUnit($scores);
  const [isExpanded, setExpanding] = useState(false);
  const [isPhoneInputFocused, setPhoneInputFocused] = useState(false);
  const [isContinueButtonClicked, setContinueButtonClick] = useState(false);

  const { handlePhoneInput, handlePay, handleSendCode, accuralPoints, isValidPhone } = useOrder(setContinueButtonClick);

  const isNextScreenLoading = (isContinueButtonClicked && isBanksListPending) || isPayLinkPending || isTableInfoPending || isScoresPending;

  const dishesCounter = `Блюда (${billData?.items.length ?? '...'} шт.)`;
  const scoresAmount = scores > 0 ? `-${scores}` : `+${accuralPoints}`;
  const scoresTitle = `Будет ${scores > 0 ? 'списано' : 'начислено'} баллов:`;
  const rawPrice = billData?.totalWOPoints ? `${Math.floor(billData?.totalWOPoints / 100)}${billData?.totalWOPoints % 100 > 0 ? `,${billData?.totalWOPoints % 100}` : ''}` : '...';
  const authPrice = authData ? billData?.totalWOPoints : billData?.total;
  const totalPrice = authPrice ? `${Math.floor(authPrice / 100)}${authPrice % 100 > 0 ? `,${authPrice % 100}` : ''}` : '...';

  return (
    <section className={styles.container}>
      <Header backUrl={`/?id=${id}`} isBoxVisible={true} />
      <h2 className={styles.title}>Ваш заказ:</h2>
      <BillItem price={rawPrice} title={dishesCounter} />
      {isExpanded && <OrderPopup handlePopup={setExpanding} />}
      {billData?.items && <BillToggler setExpanding={setExpanding} />}
      {userScores && <BillItem isPrice={false} price={scoresAmount} title={scoresTitle} />}
      {!!billData?.totalWOPoints && (scores > 0 ? <Total /> : <BillItem price={totalPrice} title='Итого:' />)}
      <Divider />

      {!authData && !isScoresPending &&
        <>
          <div>
            <InputMask
              className={`${inputStyles.input} ${phone || isPhoneInputFocused ? inputStyles.inputFocused : ''}`}
              inputmode='numeric'
              mask='+7 (999) 999 99-99'
              onBlur={() => setPhoneInputFocused(false)}
              onFocus={() => setPhoneInputFocused(true)}
              onInput={handlePhoneInput}
              placeholder='+7 (___) ___ __-__'
              placeholderTextColor='#A8A8A8'
              value={phone}
            />
            <p
              className={`${inputStyles.label} ${phone || isPhoneInputFocused ? inputStyles.labelActive : ''}`}>Номер
              телефона</p>
            <Button
              className={styles.nextButton}
              disabled={!isValidPhone || isWorkingSendTimeout}
              icon={<ArrowRightIcon disabled={!isValidPhone || isWorkingSendTimeout} />}
              onClick={handleSendCode}
              theme='highlighted'
            />
          </div>
        </>
      }
      <div className={styles.sbp}>
        <Button
          className={styles.button}
          disabled={billData?.totalWOPoints === 0}
          image={Sbp}
          isLoading={isNextScreenLoading}
          onClick={handlePay}
          text='Оплатить через'
        />
      </div>
      {isConfirmPopupVisible && <SMSCode />}
      {authData && <Scores />}
      <Cookie />
    </section>
  );
};

export default memo(Order);
