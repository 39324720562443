import { sample } from 'effector';
import {
  $isPaymentInited,
  $payLink, $paymentStatus, getAppRatingFx,
  getLayoutIdFx,
  getPayLinkFx, getPaymentInitStatusFx,
  getPaymentStatusFx,
  getTipsFx, InstallAppGate, PaymentGate,
  TipsGate,
} from '.';
import { $id, $locationData } from 'models/Table';
import { $scores, paySBP } from 'models/Order';
import { BanksGate, onChooseBank, setForbidGoBack } from 'models/Banks';
import { onOpenBank } from 'models/Payment/utils';

sample({
  clock: paySBP,
  source: { locationData: $locationData, scores: $scores },
  target: getPayLinkFx,
});

sample({
  clock: [getPayLinkFx.doneData, $locationData],
  source: { isBankPageOpened: BanksGate.status, locationData: $locationData },
  filter: ({ isBankPageOpened, locationData }) => !!locationData && isBankPageOpened,
  target: getPaymentInitStatusFx,
});

sample({
  clock: [PaymentGate.open, $locationData],
  source: {
    isPaymentStatusPending: getPaymentStatusFx.pending,
    locationData: $locationData,
    paymentStatus: $paymentStatus,
  },
  filter: ({
             isPaymentStatusPending,
             locationData,
             paymentStatus,
           }) => !!locationData && !isPaymentStatusPending && !paymentStatus,
  target: getPaymentStatusFx,
});

sample({
  clock: onChooseBank,
  source: { isBankPageOpened: BanksGate.status, isPaymentInited: $isPaymentInited, locationData: $locationData },
  filter: ({
             isBankPageOpened,
             isPaymentInited,
             locationData,
           }) => !!locationData && isBankPageOpened && !!isPaymentInited?.paymentInitiated,
  target: getPaymentStatusFx,
});

sample({
  clock: [InstallAppGate.open],
  target: getAppRatingFx,
});

sample({
  clock: [TipsGate.open, $id],
  source: $locationData,
  filter: (id) => !!id,
  target: getTipsFx,
});

sample({
  clock: onChooseBank,
  source: { id: $id, payLink: $payLink },
  filter: ({ id, payLink }) => !!id && !!payLink,
  fn: onOpenBank,
  target: setForbidGoBack,
});

sample({
  clock: getTipsFx.doneData,
  target: getLayoutIdFx,
});
