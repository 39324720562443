import { h } from 'preact';
import styles from './ProgressBar.module.scss';
import { useUnit } from 'effector-react';
import { $scores, setScores } from 'models/Order';
import { $userScores } from 'models/User';

const ProgressBar = () => {
  const userScores = useUnit($userScores);
  const totalPoints = userScores?.maxWriteOffAmount ?? 0;
  const scores = useUnit($scores);

  const handleMove = (e) => {
    const scores = e.target.value;
    setScores(scores);
  };


  return (
    <div className={styles.container}>
      <div className={styles.data}>
        <p className={styles.label}>Возможно списать баллов:</p>
        <div className={styles.values}>
          <p
            className={styles.available}
            style={{ textDecoration: totalPoints > 0 ? 'underline' : 'none' }}>
            {scores}
          </p>
          <p>&nbsp;из&nbsp;</p>
          <p className={styles.value}>{totalPoints < 0 ? 0 : totalPoints}</p>
        </div>
      </div>
      <div className={styles.progressContainer}>
        <input
          className={styles.input}
          defaultValue={userScores?.selectedWriteOffAmount ?? scores}
          disabled={totalPoints <= 0}
          min={0}
          max={totalPoints}
          onChange={handleMove}
          step={1}
          style={{ backgroundSize: `${totalPoints > 0 ? (scores / totalPoints * 100) : 0}% 100%` }} type='range'
          value={scores}
        />
      </div>
    </div>
  );
};

export default ProgressBar;
