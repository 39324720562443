import { h } from 'preact';
import styles from './Scores.module.scss';
import ArrowDownIcon from 'assets/icons/ArrowDownIcon';
import ProgressBar from '../ProgressBar';
import Divider from 'shared/ui/Divider';
import { useState } from 'preact/hooks';

const Scores = () => {
  const [isUsingScores, setUsingScores] = useState(false);

  return (
    <div>
      <div className={styles.title} onClick={() => setUsingScores(prev => !prev)}>
        <p className={styles.label}>Использовать баллы</p>
        <ArrowDownIcon style={{ transform: `rotate(${isUsingScores ? 180 : 0}deg)` }} />
      </div>
      {isUsingScores && <ProgressBar />}
      <Divider />
    </div>
  );
};

export default Scores;
