import { h } from 'preact';

const Star = () => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.58379 0.603032C5.93275 -0.197525 7.06823 -0.197524 7.41719 0.603033L8.29181 2.60954C8.42724 2.92024 8.71083 3.14119 9.04521 3.19654L11.191 3.55171C11.9721 3.68099 12.3003 4.62347 11.7686 5.20997L10.0695 7.08401C9.86763 7.30666 9.7766 7.60829 9.82161 7.90544L10.2008 10.4089C10.3272 11.2438 9.42591 11.8499 8.70039 11.4179L7.01215 10.4125C6.69688 10.2247 6.3041 10.2247 5.98882 10.4125L4.30058 11.4179C3.57507 11.8499 2.67374 11.2438 2.80019 10.4089L3.17937 7.90543C3.22437 7.60829 3.13335 7.30666 2.93148 7.08401L1.2324 5.20997C0.700654 4.62347 1.02891 3.68099 1.80994 3.55171L3.95577 3.19654C4.29015 3.14119 4.57374 2.92024 4.70917 2.60954L5.58379 0.603032Z" fill="#FCD921"/>
    </svg>
  );
};

export default Star;
