import { createEffect, createEvent, createStore } from 'effector';
import {
  getAppRating,
  getLayoutId,
  getPayLink,
  getPaymentInitStatus,
  getPaymentStatus,
  getTips,
} from 'shared/api/endpoints';
import { createGate } from 'effector-react';

export const getAppRatingFx = createEffect<any, any>(getAppRating);
export const getLayoutIdFx = createEffect<any, any>(getLayoutId);
export const getPayLinkFx = createEffect<any, any>(getPayLink);
export const getPaymentInitStatusFx = createEffect<any, any>(getPaymentInitStatus);
export const getPaymentStatusFx = createEffect<any, any>(getPaymentStatus);
export const getTipsFx = createEffect<any, any>(getTips);

export const onOpenApp = createEvent<void>();

export const InstallAppGate = createGate<string>('InstallAppPage');
export const PaymentGate = createGate<string>('PaymentPage');
export const TipsGate = createGate<string>('TipsPage');

export const $appRating = createStore<any>(null)
  .on(getAppRatingFx.doneData, (_, data) => data);
export const $isPaymentInited = createStore<any>(false)
  .on(getPaymentInitStatusFx.doneData, (_, data) => data);
export const $layoutId = createStore<any>(null)
  .on(getLayoutIdFx.doneData, (_, data) => data);
export const $payLink = createStore<any>(null)
  .on(getPayLinkFx.doneData, (_, data) => data);
export const $paymentStatus = createStore<any>(null)
  .on(getPaymentStatusFx.doneData, (_, data) => data);
export const $tips = createStore<any>(null)
  .on(getTipsFx.doneData, (_, data) => data);
