import { h } from 'preact';

const LoaderWhiteIcon = ({ className }) => {
  return (
    <svg className={className} width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path opacity='0.6'
            d='M12.7212 -4.59354e-05C13.2825 0.118045 13.7712 0.362055 14.1141 0.8394C14.6121 1.5323 14.6783 2.27784 14.287 3.02836C13.8851 3.79951 13.2149 4.18864 12.3428 4.14027C11.88 4.11855 11.4382 3.9409 11.0892 3.6362C10.7402 3.33151 10.5045 2.9177 10.4206 2.46209C10.2456 1.53728 10.7009 0.635941 11.5752 0.203414C11.7608 0.111644 11.9686 0.0661137 12.1663 -4.59839e-05L12.7212 -4.59354e-05Z'
            fill='white' />
      <path
        d='M15.5639 22.8535C15.5489 23.6711 15.2127 24.4499 14.6281 25.0217C14.0435 25.5935 13.2574 25.9123 12.4397 25.9092C11.6219 25.9061 10.8382 25.5814 10.258 25.0052C9.67769 24.4291 9.34742 23.6477 9.33853 22.83C9.33405 22.4174 9.41164 22.008 9.5668 21.6256C9.72196 21.2433 9.95159 20.8956 10.2423 20.6027C10.5331 20.3099 10.8792 20.0778 11.2604 19.92C11.6417 19.7621 12.0505 19.6816 12.4631 19.6831C12.8758 19.6847 13.284 19.7683 13.6641 19.929C14.0441 20.0898 14.3884 20.3245 14.6769 20.6195C14.9655 20.9145 15.1925 21.2639 15.3447 21.6474C15.497 22.0309 15.5715 22.4409 15.5639 22.8535Z'
        fill='white' />
      <path
        d='M19.7119 22.5703C19.148 22.5685 18.5973 22.3994 18.1296 22.0844C17.6619 21.7694 17.2982 21.3227 17.0845 20.8008C16.8709 20.279 16.8168 19.7055 16.9293 19.1529C17.0418 18.6003 17.3157 18.0936 17.7164 17.6968C18.1171 17.3 18.6265 17.031 19.1801 16.924C19.7337 16.8169 20.3067 16.8765 20.8264 17.0952C21.3462 17.314 21.7893 17.682 22.0997 18.1528C22.4102 18.6235 22.5739 19.1758 22.5703 19.7397C22.5711 20.114 22.4976 20.4848 22.3539 20.8304C22.2102 21.1761 21.9993 21.4897 21.7333 21.7531C21.4673 22.0165 21.1517 22.2243 20.8046 22.3647C20.4576 22.505 20.0862 22.5749 19.7119 22.5703Z'
        fill='white' />
      <path
        d='M22.8237 9.86775C23.1643 9.86475 23.5022 9.92912 23.8179 10.0572C24.1336 10.1852 24.4208 10.3744 24.6632 10.6139C24.9055 10.8533 25.098 11.1383 25.2298 11.4525C25.3616 11.7666 25.4299 12.1037 25.4309 12.4444C25.4494 13.8558 24.2778 15.0374 22.8472 15.0502C21.4165 15.0631 20.2655 13.912 20.2406 12.4679C20.2164 11.0608 21.386 9.88055 22.8237 9.86775Z'
        fill='white' />
      <path opacity='0.8'
            d='M22.0502 5.18032C22.0535 5.48671 21.9959 5.79069 21.8808 6.07466C21.7657 6.35863 21.5954 6.61692 21.3798 6.83457C21.1641 7.05222 20.9074 7.22488 20.6245 7.34256C20.3416 7.46024 20.0381 7.52058 19.7317 7.52009C19.2699 7.52305 18.8175 7.38888 18.432 7.13457C18.0465 6.88026 17.7451 6.51726 17.566 6.09154C17.3869 5.66581 17.3382 5.19652 17.426 4.74309C17.5138 4.28966 17.7342 3.87249 18.0593 3.54441C18.3844 3.21634 18.7995 2.99211 19.2521 2.90014C19.7047 2.80817 20.1745 2.85258 20.6018 3.02776C21.0292 3.20294 21.3949 3.50099 21.6527 3.88419C21.9106 4.26738 22.0489 4.71847 22.0502 5.18032Z'
            fill='white' />
      <path opacity='0.4'
            d='M3.37752 5.20023C3.37544 4.84282 3.47924 4.4928 3.67583 4.1943C3.87241 3.8958 4.15299 3.6622 4.48216 3.52294C4.81134 3.38369 5.17438 3.34502 5.5255 3.41182C5.87663 3.47861 6.20011 3.64788 6.45516 3.89828C6.71021 4.14867 6.88541 4.46898 6.95866 4.81881C7.03191 5.16864 6.99993 5.53233 6.86676 5.86401C6.73359 6.19569 6.50518 6.48052 6.21035 6.68256C5.91552 6.88461 5.56747 6.99484 5.21008 6.99934C4.97132 7.00263 4.73428 6.95859 4.51264 6.86976C4.291 6.78092 4.08916 6.64906 3.91877 6.48178C3.74838 6.3145 3.61282 6.11512 3.51992 5.89516C3.42702 5.6752 3.37862 5.43901 3.37752 5.20023Z'
            fill='white' />
      <path opacity='0.24'
            d='M2.08374 14.0052C1.7769 14.0062 1.47664 13.9162 1.22087 13.7467C0.965106 13.5772 0.765286 13.3357 0.646635 13.0527C0.527985 12.7697 0.495823 12.458 0.554207 12.1567C0.612592 11.8555 0.758906 11.5783 0.974684 11.3601C1.19046 11.142 1.46603 10.9926 1.76661 10.931C2.06719 10.8693 2.3793 10.898 2.66356 11.0135C2.94782 11.1291 3.19148 11.3262 3.36379 11.5801C3.5361 11.834 3.62933 12.1333 3.63173 12.4401C3.63417 12.6449 3.59593 12.8481 3.51922 13.038C3.4425 13.2278 3.32885 13.4006 3.18484 13.5462C3.04083 13.6918 2.86934 13.8073 2.68032 13.8861C2.4913 13.9649 2.28852 14.0054 2.08374 14.0052Z'
            fill='white' />
      <path opacity='0.12'
            d='M3.89848 19.7049C3.90955 19.3662 4.05305 19.0453 4.29815 18.8113C4.54325 18.5772 4.87038 18.4487 5.20924 18.4532C5.54811 18.4578 5.87166 18.5951 6.11036 18.8357C6.34907 19.0762 6.48387 19.4008 6.48581 19.7397C6.48695 19.9118 6.45377 20.0824 6.3882 20.2414C6.32264 20.4005 6.226 20.5449 6.10395 20.6662C5.9819 20.7875 5.83689 20.8832 5.6774 20.9478C5.51791 21.0124 5.34715 21.0445 5.1751 21.0423C5.00305 21.04 4.83317 21.0035 4.6754 20.9348C4.51763 20.8662 4.37514 20.7667 4.25627 20.6423C4.13739 20.5179 4.04452 20.3711 3.98309 20.2104C3.92166 20.0496 3.89289 19.8783 3.89848 19.7063L3.89848 19.7049Z'
            fill='white' />
    </svg>
  );
};

export default LoaderWhiteIcon;
