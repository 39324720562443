import { route } from 'preact-router';

export const getLastUsedBank = () => {
  return localStorage.getItem('lastBankId');
};

export const setLastUsedBank = (bankId) => {
  return localStorage.setItem('lastBankId', bankId);
};

export const getBanksWithLastUsedBank = (rawBanks) => {
  const banks = rawBanks;
  const lastBankId = getLastUsedBank();
  if (lastBankId && lastBankId !== '0') {
    const savedIndex = banks.findIndex(item => lastBankId === item.schema);
    const savedElem = banks[savedIndex];
    banks.splice(savedIndex, 1);
    banks.splice(0, 0, savedElem);
  }
  return banks;
};

export const redirectToPayPage = (id) => {
  route(`/payment?id=${id}`);
}
