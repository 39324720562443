import { h } from 'preact';
import styles from './HomeHeader.module.scss';
import Logo from 'assets/images/logo.jpg';
import Skeleton from 'react-loading-skeleton';
import { useUnit } from 'effector-react';
import { $locationData } from 'models/Table';

const HomeHeader = () => {
  const locationData = useUnit($locationData);

  return (
    <header className={styles.container}>
      <img alt='Кофемания' className={styles.logo} src={Logo} />
      {locationData ? (
        <h1 className={styles.title}>{locationData?.name || 'Произошла ошибка'}</h1>
      ) : (
        <Skeleton containerClassName={styles.loader} />
      )}
    </header>
  );
};

export default HomeHeader;
