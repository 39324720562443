import { h, FunctionalComponent } from 'preact';
import styles from './Bank.module.scss';
import { BankProps } from './interfaces';

const Bank: FunctionalComponent<BankProps> = ({ className, logo, name, onClick }) => {
  return (
    <div className={`${styles.container}${className ? ' ' + className : ''}`} onClick={onClick}>
      {logo && <img className={styles.logo} src={logo} />}
      <p className={styles.name}>{name}</p>
    </div>
  );
};

export default Bank;
