import { h } from 'preact';

const SmallSuccessIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='12' cy='12' r='12' fill='white' />
      <path d='M6.54541 12.2132L10.0397 15.7091L17.4545 8.29089' stroke='#48907F' stroke-width='2'
            stroke-linecap='round' />
    </svg>
  );
};

export default SmallSuccessIcon;
