import { h } from 'preact';

const MessageIcon = () => {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 7.36846V7.70339C0 11.2468 2.60771 14.1781 6 14.6657V15.9069C6 17.6782 8.05596 18.6457 9.41013 17.5118L12.7244 14.7367C16.7479 14.7056 20 11.4186 20 7.36847C20 3.29898 16.7168 0 12.6667 0H7.33333C3.28324 0 0 3.29897 0 7.36846ZM11.6888 12.9879L8.12976 15.968C8.07823 16.0111 8 15.9743 8 15.9069V13.7321C8 13.6472 7.98952 13.5648 7.96979 13.4861C7.86056 13.0501 7.46779 12.7274 7 12.7274C4.23858 12.7274 2 10.478 2 7.70339V7.36846C2 4.40884 4.38781 2.00958 7.33333 2.00958H12.6667C15.6122 2.00958 18 4.40884 18 7.36847C18 10.3281 15.6122 12.7274 12.6667 12.7274H12.4051C12.1434 12.7274 11.8899 12.8196 11.6888 12.9879Z" fill="#212121"/>
    </svg>
  );
};

export default MessageIcon;
