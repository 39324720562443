import { h } from 'preact';
import styles from './OrderPopup.module.scss';
import CloseIcon from 'assets/icons/CloseIcon';
import BillItem from '../BillItem';
import { useUnit } from 'effector-react';
import { $billData } from 'models/Order';
import OrderItem from './components/OrderItem';

const OrderPopup = ({ handlePopup }) => {
  const billData = useUnit($billData);
  const rawPrice = billData?.totalWOPoints ? `${Math.floor(billData?.totalWOPoints / 100)}${billData?.totalWOPoints % 100 > 0 ? `,${billData?.totalWOPoints % 100}` : ''}` : '...';

  return (
    <div className={styles.bg}>
      <div className={styles.container}>
        <h3 className={styles.title}>Ваш заказ</h3>
        <CloseIcon className={styles.closeButton} onClick={() => handlePopup(false)} />
        <div className={styles.list}>
          <BillItem price={rawPrice} title={`Блюда (${billData?.items.length ?? '...'} шт.)`} />
          {billData?.items.map(({ name, originalPrice }) => (
            <OrderItem
              price={`${Math.floor(originalPrice / 100)}${originalPrice % 100 > 0 ? `,${originalPrice % 100}` : ''}`}
              title={name}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default OrderPopup;
