import { v4 as uuidv4 } from 'uuid';
import { sha512 } from 'js-sha512';
import { route } from 'preact-router';

export const authDTO = ({ tableUid }) => {
  const salt = uuidv4();
  const phone = localStorage.getItem('phone');

  if (!tableUid || !phone) {
    return null;
  }

  const sign = sha512.hmac(tableUid + salt, phone);

  return { salt, tableUid, sign };
};

export const phoneSetter = (phoneRaw) => {
  const phone = phoneRaw.replace(/[^0-9]/g, '');
  localStorage.setItem('phone', phone);

  const code = phone.substring(1, 4);
  const middle = phone.substring(4, 7);
  const lastFirst = phone.substring(7, 9);
  const lastSecond = phone.substring(9, 11);

  return `+7 ${code} ${middle} ${lastFirst} ${lastSecond}`;
};

export const phoneGetter = () => {
  const phone = localStorage.getItem('phone');

  const code = phone.substring(1, 4);
  const middle = phone.substring(4, 7);
  const lastFirst = phone.substring(7, 9);
  const lastSecond = phone.substring(9, 11);

  return `+7 ${code} ${middle} ${lastFirst} ${lastSecond}`;
};

export const onLogoutFn = (id) => {
  localStorage.removeItem('phone');
  route(`/?id=${id}`);
  return;
};

export const sendCodeDTO = ({ locationData, phone }) => {
  return {
    locationData,
    phone: parseInt(phone.replace(/[^0-9]/g, '')),
  };
};
