import { h } from 'preact';
import './models/init';
import { Router, Route } from 'preact-router';
// Code-splitting is automated for `routes` directory
import Home from './routes/home';
import Order from './routes/order';
import Banks from './routes/banks';
import { useGate, useUnit } from 'effector-react';
import { IndexGate } from 'models/Table';
import Payment from './routes/payment';
import { useEffect } from 'preact/hooks';
import { $errorPopupData } from 'models/Request';
import ErrorPopup from 'shared/ui/ErrorPopup';

const App = () => {
  if (typeof window !== 'undefined') {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('id');
    const errorPopupData = useUnit($errorPopupData);

    useGate(IndexGate, id);

    useEffect(() => {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (let registration of registrations) {
          registration.unregister();
        }
      });
    }, []);

    return (
      <div id='app'>
        <Router>
          <Route path='/' component={Home} />
          <Route path='/order' component={Order} />
          <Route path='/payment' component={Payment} />
          <Route path='/banks' component={Banks} />
        </Router>
        {errorPopupData && <ErrorPopup />}
      </div>
    );
  }
};

export default App;
