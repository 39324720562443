import { h } from 'preact';
import { useGate } from 'effector-react';
import { $paymentStatus, PaymentGate } from 'models/Payment';
import { useUnit } from 'effector-react';
import 'react-loading-skeleton/dist/skeleton.css';
import Tips from './components/Tips';
import { route } from 'preact-router';

const Payment = () => {
  useGate(PaymentGate);
  const paymentStatus = useUnit($paymentStatus);

  if (paymentStatus?.success === true) return <Tips />;
  if (paymentStatus?.success === false) route('/banks');

  return <></>;
};

export default Payment;
