import { h } from 'preact';
import VkIcon from 'assets/icons/VkIcon';
import TelegramIcon from 'assets/icons/TelegramIcon';

export const socialNetworks = {
  vk: {
    logo: <VkIcon />,
    url: 'https://vk.com/coffeemaniaru',
  },
  telegram: {
    logo: <TelegramIcon />,
    url: 'https://t.me/coffeemaniaru',
  },
};
