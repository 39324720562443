import { h, FunctionComponent } from 'preact';
import { memo } from 'preact/compat';
import styles from './Button.module.scss';
import { classNames } from './utils';
import { ButtonProps } from './interfaces';
import ButtonLoader from './components/ButtonLoader';

const Button: FunctionComponent<ButtonProps> = ({
                                                  className = '',
                                                  contentClassName = '',
                                                  disabled = false,
                                                  icon,
                                                  image,
                                                  isLoading = false,
                                                  onClick,
                                                  style = {},
                                                  subtitle,
                                                  text = '',
                                                  theme = 'default',
                                                }) => {
  //if (isLoading) return <button className={`${styles.container} ${classNames[theme]}`}><LoaderRedIcon className={styles.loader} /></button>

  return (
    <button
      className={`${styles.container} ${classNames[theme]}${className ? ' ' + className : ''}${disabled ? ` ${styles.disabled}` : ''}`}
      onClick={(!disabled && !isLoading) ? onClick : undefined}
      style={style}
    >
      {isLoading ? (
        <ButtonLoader isHighlighted={theme === 'highlighted'} />
      ) : (
        <>
          <div className={`${styles.content}${contentClassName ? ' ' + contentClassName : ''}`}>
            {icon}
            {text}
            {image && <img alt='Загрузка' className={styles.image} src={image} />}
          </div>
          {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
        </>
      )}
    </button>
  );
};

export default memo(Button);
