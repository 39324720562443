import { route } from 'preact-router';
import Sbp from '../../assets/images/sbp.png';

export const redirectLink = (link: string, id: string, isExternal: boolean, event: string | null) => {
  if (event) {
    // @ts-ignore: Unreachable code error
    ym(91804168, 'reachGoal', event);
  }

  if (isExternal) {
    window.location.href = link;
  } else {
    route(link + `?id=${id}`);
  }
};
