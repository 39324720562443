import EmailIcon from 'assets/icons/EmailIcon';
import PhoneIcon from 'assets/icons/PhoneIcon';
import TelegramIcon from 'assets/icons/TelegramIcon';

export const popupFooterButtons = [
  {
    icon: <EmailIcon />,
    link: 'mailto:feedback@coffeemania.ru',
    text: 'Написать письмо',
  },
  // {
  //   link: 'mailto:feedback@coffeemania.ru',
  //   text: 'Оставить отзыв на карте '
  // },
  {
    icon: <PhoneIcon />,
    link: 'tel:84951201203',
    text: 'Позвонить',
  },
  {
    icon: <TelegramIcon />,
    link: 'https://telegram.me/Coffeemania_zabotabot',
    text: 'Написать в Telegram',
  },
];
