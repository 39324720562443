import { createEffect, createEvent, createStore } from 'effector';
import { createGate } from 'effector-react';
import { getTableInfo } from 'shared/api/endpoints';

export const getTableInfoFx = createEffect<string, any>(getTableInfo);

export const onGetTableData = createEvent<void>();
export const fetchOrder = createEvent<void>();
export const paySBP = createEvent<void>();
export const resetData = createEvent<void>();
export const setPhone = createEvent<void>();
export const setScores = createEvent<number>();
export const setConfirmPopupVisibility = createEvent<boolean>();
export const setOrderPopupVisibility = createEvent<boolean>();

export const OrderGate = createGate<void>('OrderPage')

export const $billData = createStore<any>(null)
  .on(getTableInfoFx.doneData, (_, data) => data);
export const $phone = createStore<string>('')
  .on(setPhone, (_, data) => data);
export const $scores = createStore<number>(0)
  .on(setScores, (_, data) => data);
