import { h } from 'preact';

const ArrowDownIcon = ({ className = '', style = {} }) => {
  return (
    <svg className={className} style={style} width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.209454 0.21967C0.4884 -0.0732233 0.94066 -0.0732233 1.21961 0.21967L5.00024 4.18934L8.78088 0.21967C9.05983 -0.0732233 9.51209 -0.0732233 9.79103 0.21967C10.07 0.512563 10.07 0.987437 9.79103 1.28033L5.50532 5.78033C5.22637 6.07322 4.77411 6.07322 4.49517 5.78033L0.209454 1.28033C-0.0694924 0.987437 -0.0694924 0.512563 0.209454 0.21967Z'
        fill='#376F62' />
    </svg>
  );
};

export default ArrowDownIcon;
