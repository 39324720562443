import { h } from 'preact';

const LoaderRedIcon = ({ className }) => {
  return (
    <svg className={className} width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_11624_64284)'>
        <path
          d='M15.3218 30C14.6741 29.8637 14.1102 29.5822 13.7146 29.0314C13.14 28.2319 13.0636 27.3717 13.5151 26.5057C13.9789 25.6159 14.7521 25.1669 15.7585 25.2227C16.2924 25.2478 16.8022 25.4528 17.2049 25.8043C17.6076 26.1559 17.8795 26.6334 17.9764 27.1591C18.1783 28.2262 17.6529 29.2662 16.6441 29.7652C16.4299 29.8711 16.1902 29.9237 15.962 30H15.3218Z'
          fill='#E1523E' />
        <path
          d='M12.0417 3.63054C12.0591 2.68715 12.4469 1.78848 13.1215 1.12873C13.796 0.468984 14.7031 0.101143 15.6466 0.104701C16.5902 0.108259 17.4944 0.48293 18.164 1.14775C18.8335 1.81256 19.2146 2.71413 19.2248 3.65763C19.23 4.13373 19.1405 4.60611 18.9615 5.0473C18.7824 5.48849 18.5175 5.88969 18.182 6.22755C17.8465 6.56542 17.4472 6.83322 17.0073 7.01538C16.5674 7.19754 16.0957 7.29042 15.6195 7.28863C15.1434 7.28683 14.6724 7.19039 14.2339 7.00492C13.7953 6.81945 13.3981 6.54865 13.0651 6.20826C12.7322 5.86787 12.4703 5.46469 12.2946 5.02216C12.1189 4.57963 12.0329 4.10659 12.0417 3.63054Z'
          fill='#E1523E' />
        <path
          d='M7.25558 3.95727C7.90623 3.9594 8.54161 4.15455 9.08127 4.51801C9.62093 4.88147 10.0406 5.3969 10.2871 5.99903C10.5337 6.60116 10.596 7.26291 10.4662 7.90048C10.3364 8.53805 10.0204 9.12277 9.55806 9.5806C9.09574 10.0384 8.50797 10.3488 7.86916 10.4723C7.23035 10.5959 6.56925 10.5271 5.96955 10.2747C5.36986 10.0223 4.85855 9.59764 4.50036 9.05446C4.14218 8.51127 3.95324 7.87402 3.95746 7.22338C3.95645 6.79148 4.0413 6.36369 4.20709 5.96488C4.37288 5.56607 4.61629 5.20419 4.92317 4.90028C5.23006 4.59637 5.59429 4.3565 5.9947 4.19461C6.39512 4.03272 6.82371 3.95205 7.25558 3.95727Z'
          fill='#E1523E' />
        <path
          d='M3.66504 18.6141C3.27198 18.6176 2.88211 18.5433 2.51786 18.3956C2.15361 18.2478 1.82216 18.0295 1.54258 17.7532C1.26299 17.4769 1.04079 17.1481 0.888757 16.7856C0.73672 16.4231 0.657846 16.0341 0.656671 15.6411C0.635329 14.0125 1.98725 12.6491 3.63796 12.6343C5.28866 12.6196 6.61678 13.9477 6.64551 15.614C6.67342 17.2376 5.32396 18.5994 3.66504 18.6141Z'
          fill='#E1523E' />
        <path
          d='M4.55757 24.0226C4.55376 23.6691 4.62018 23.3183 4.75296 22.9907C4.88575 22.663 5.08226 22.365 5.3311 22.1139C5.57994 21.8627 5.87616 21.6635 6.20258 21.5277C6.52901 21.3919 6.87914 21.3223 7.23268 21.3229C7.76558 21.3195 8.28751 21.4743 8.73236 21.7677C9.17721 22.0611 9.52498 22.48 9.73162 22.9712C9.93825 23.4624 9.99446 24.0039 9.89313 24.5271C9.7918 25.0503 9.53748 25.5316 9.16238 25.9102C8.78729 26.2887 8.30829 26.5475 7.78605 26.6536C7.26381 26.7597 6.72182 26.7085 6.22873 26.5063C5.73564 26.3042 5.31362 25.9603 5.01613 25.5181C4.71863 25.076 4.55904 24.5555 4.55757 24.0226Z'
          fill='#E1523E' />
        <path
          d='M26.1029 23.9997C26.1053 24.4121 25.9856 24.8159 25.7587 25.1604C25.5319 25.5048 25.2082 25.7743 24.8283 25.935C24.4485 26.0957 24.0296 26.1403 23.6245 26.0632C23.2193 25.9862 22.8461 25.7909 22.5518 25.5019C22.2575 25.213 22.0554 24.8434 21.9708 24.4398C21.8863 24.0361 21.9232 23.6165 22.0769 23.2338C22.2305 22.8511 22.4941 22.5224 22.8343 22.2893C23.1745 22.0562 23.5761 21.929 23.9884 21.9238C24.2639 21.92 24.5374 21.9708 24.7932 22.0733C25.0489 22.1758 25.2818 22.328 25.4784 22.521C25.675 22.714 25.8314 22.944 25.9386 23.1978C26.0458 23.4516 26.1016 23.7242 26.1029 23.9997Z'
          fill='#E1523E' />
        <path
          d='M27.5958 13.8401C27.9498 13.839 28.2963 13.9428 28.5914 14.1383C28.8865 14.3339 29.1171 14.6126 29.254 14.9391C29.3909 15.2656 29.428 15.6253 29.3606 15.9729C29.2932 16.3205 29.1244 16.6403 28.8754 16.8921C28.6265 17.1438 28.3085 17.3161 27.9617 17.3873C27.6149 17.4585 27.2547 17.4253 26.9267 17.292C26.5987 17.1587 26.3176 16.9312 26.1188 16.6382C25.92 16.3453 25.8124 16 25.8096 15.6459C25.8068 15.4097 25.8509 15.1752 25.9394 14.9561C26.028 14.737 26.1591 14.5377 26.3253 14.3697C26.4914 14.2017 26.6893 14.0684 26.9074 13.9775C27.1255 13.8866 27.3595 13.8399 27.5958 13.8401Z'
          fill='#E1523E' />
        <path
          d='M25.5018 7.26358C25.489 7.65441 25.3234 8.02462 25.0406 8.29467C24.7578 8.56473 24.3804 8.71306 23.9894 8.70779C23.5984 8.70252 23.2251 8.54408 22.9496 8.2665C22.6742 7.98893 22.5187 7.61439 22.5164 7.22336C22.5151 7.02483 22.5534 6.82803 22.629 6.64447C22.7047 6.46092 22.8162 6.29429 22.957 6.15435C23.0978 6.0144 23.2652 5.90395 23.4492 5.82945C23.6332 5.75495 23.8303 5.7179 24.0288 5.72046C24.2273 5.72303 24.4233 5.76516 24.6054 5.84439C24.7874 5.92362 24.9518 6.03836 25.089 6.1819C25.2261 6.32544 25.3333 6.49489 25.4042 6.68034C25.4751 6.86578 25.5082 7.06351 25.5018 7.26194V7.26358Z'
          fill='#E1523E' />
      </g>
      <defs>
        <clipPath id='clip0_11624_64284'>
          <rect width='30' height='30' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LoaderRedIcon;
