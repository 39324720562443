import { h } from 'preact';
import styles from './OrderItem.module.scss';

const OrderItem = ({ title, price }) => {
  return (
    <div className={styles.container}>
      <p className={styles.title}>{title}</p>
      <p className={styles.price}>{price} ₽</p>
    </div>
  );
};

export default OrderItem;
