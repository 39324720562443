import { callCode, checkAuth, confirmCode, getScores, logout, sendCode } from 'shared/api/endpoints';
import { createEffect, createEvent, createStore } from 'effector';
import { createGate } from 'effector-react';
import { createCountdown } from './Timeout';
import { setConfirmPopupVisibility } from 'models/Order';

export const sendCodeFx = createEffect<any, any>(sendCode);
export const callCodeFx = createEffect<any, any>(callCode);
export const confirmCodeFx = createEffect<any, any>(confirmCode);
export const checkAuthFx = createEffect<any, any>(checkAuth);
export const getScoresFx = createEffect<any, any>(getScores);
export const logoutFx = createEffect<any, any>(logout);

export const startVerificationCountdown = createEvent();
export const abortVerificationCountdown = createEvent();
export const onCallCode = createEvent<void>();
export const onCheckAuth = createEvent<void>();
export const onConfirmCode = createEvent<string>();
export const onLogout = createEvent<any>();
export const onSendCode = createEvent<void>();
export const onSetSliderScores = createEvent<void>();
export const onVerificationError = createEvent<any>();

export const SMSCodeGate = createGate<void>();

export const $authData = createStore<any>(null);
export const $codeVerification = createStore<any>(null)
  .on(confirmCodeFx.doneData, (_, data) => data);
export const $isCallVerificationMode = createStore<boolean>(false)
  .on(onCallCode, () => true).on(onSendCode, () => false);
export const $isConfirmPopupVisible = createStore<boolean>(false)
  .on(setConfirmPopupVisibility, (_, data) => data);
export const $isSave = createStore<boolean>(true);
export const $isWorkingSendTimeout = createStore(false);
export const $userScores = createStore<any>(null)
  .on(getScoresFx.doneData, (_, data) => data?.result);
export const $verificationError = createStore<string>('');

const onTickTimeout = createCountdown({
  start: startVerificationCountdown,
  abort: abortVerificationCountdown,
  $isWorking: $isWorkingSendTimeout,
});

export const $verificationTimeout = createStore<number>(59)
  .on(onTickTimeout, (_, data) => data);
