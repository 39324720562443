import { h, FunctionComponent } from 'preact';

const FailIcon = () => {
  return (
    <svg width='110' height='110' viewBox='0 0 110 110' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle opacity='0.05' cx='55' cy='55' r='55' fill='#E1523E' />
      <path d='M38 72L71.9844 38' stroke='#E1523E' stroke-width='6' stroke-linecap='round' />
      <path d='M72 71.9844L38 38' stroke='#E1523E' stroke-width='6' stroke-linecap='round' />
    </svg>
  );
};

export default FailIcon;