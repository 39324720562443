import { h } from 'preact';
import styles from './ButtonLoader.module.css';
import LoaderWhiteIcon from 'assets/icons/LoaderWhiteIcon';
import LoaderRedIcon from 'assets/icons/LoaderRedIcon';

const ButtonLoader = ({ isHighlighted }) => {
  return isHighlighted ? <LoaderWhiteIcon className={styles.icon} /> : <LoaderRedIcon className={styles.icon} />;
};

export default ButtonLoader;
