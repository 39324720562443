import { h } from 'preact';
import styles from './BillToggler.module.scss';
import ArrowDownIcon from 'assets/icons/ArrowDownIcon';

const BillToggler = ({ setExpanding }) => {
  return (
    <div className={styles.container} onClick={() => setExpanding(true)}>
      <p className={styles.text}>Показать список блюд</p>
      <ArrowDownIcon className={styles.arrow} />
    </div>
  );
};

export default BillToggler;
