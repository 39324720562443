import styles from './Button.module.scss';

export const classNames = {
  black: styles.black,
  default: styles.default,
  disabled: styles.disabled,
  green: styles.green,
  grey: styles.grey,
  highlighted: styles.highlighted,
};
