import { $api, $appStore, $cloudPayments, $nspk } from 'shared/api/config';
import { setErrorPopupData } from 'models/Request';

export const getLocation = async (id: string) => {
  try {
    const { data } = await $api.get(`/info/WheresWally?id=${id}`);

    if (!data.success) {
      setErrorPopupData('onGetLocation');
    }
    return data.result;
  } catch (err) {
    setErrorPopupData('onGetLocation');
    return null;
  }
};

export const getTableInfo = async (tableUid: string) => {
  try {
    const { data } = await $api.get(`/info/TableInfo?id=${tableUid}`);

    let result = data.result.checks.map(item => {
      return item.items;
    }).flat();

    if (!data.success) {
      setErrorPopupData('onGetTableData');
    }

    return {
      items: result,
      itemsCount: data.result.itemsCount,
      total: data.result.total,
      totalWOPoints: data.result.totalWOPoints,
    };
  } catch (err) {
    setErrorPopupData('onGetTableData');
    return null;
  }
};

export const checkAuth = async ({ salt, tableUid, sign }) => {
  try {
    const { data } = await $api.get(`/info/PhoneAuthorizationStatus?id=${tableUid}&phone=${sign}&salt=${salt}`);
    return data.result;

  } catch (err) {
    return null;
  }
};

export const sendCode = async ({ locationData, phone }) => {
  try {
    const { data } = await $api.get(`/tools/SendShortMessage?id=${locationData.tableUid}&phone=${phone}`);

    if (!data.success) {
      setErrorPopupData('onSendCode');
    }

    return data.success;

  } catch (e) {
    setErrorPopupData('onSendCode');
    return null;
  }
};

export const callCode = async ({ locationData, phone }) => {
  try {
    const { data } = await $api.get(`/tools/SendCall?id=${locationData.tableUid}&phone=${phone}`);

    if (!data.success) {
      setErrorPopupData('onCallCode');
    }

    return data.success;
  } catch (err) {
    setErrorPopupData('onCallCode');
    return null;
  }
};

export const confirmCode = async ({ locationData, code }) => {
  try {
    const { data } = await $api.get(`/tools/CodeVerification?id=${locationData.tableUid}&code=${code}&cookieAvailable=${true}`);
    if (data.success) {
      // @ts-ignore: Unreachable code error
      ym(91804168, 'reachGoal', 'SUCCESS_AUTH');
    }
    return data;
  } catch (err) {
    setErrorPopupData('onConfirmCode');
    return null;
  }
};

export const getScores = async ({ locationData }) => {
  try {
    const { data } = await $api.get(`/info/LoyaltyPointsForCheck?id=${locationData.tableUid}`);
    return data;
  } catch (err) {
    return null;
  }
};

export const getBanks = async () => {
  try {
    const { data } = await $nspk.get(`/c2bmembers.json`, 'omit');
    return data.dictionary;
  } catch (err) {
    return null;
  }
};

export const getPayLink = async ({ locationData, scores }) => {
  try {
    const writeOffAmount = Number(scores);
    const { data } = await $api.post(`/info/QrcPayload`, {
      redirectUrl: 'https://',
      uid: locationData.tableUid,
      writeOffAmount,
    });

    if (writeOffAmount > 0) {
      // @ts-ignore: Unreachable code error
      ym(91804168, 'reachGoal', 'SUCCESS_SCORESWRITEOFF');
    } else {
      // @ts-ignore: Unreachable code error
      ym(91804168, 'reachGoal', 'FALSE_SCORESWRITEOFF');
    }

    return data.result.qrcPayload;
  } catch (err) {
    return null;
  }
};

export const getAppRating = async () => {
  try {
    const { data } = await $appStore.get();
    return data.results[0].averageUserRating;
  } catch (err) {
    return null;
  }
};

export const getPaymentInitStatus = async ({ locationData }) => {
  try {
    const { data } = await $api.get(`/info/PaymentInitiatedStatus?id=${locationData.tableUid}`);
    return data.result;
  } catch (err) {
    return null;
  }
};

export const getPaymentStatus = async ({ locationData }) => {
  try {
    const { data } = await $api.get(`/info/PaymentStatus?id=${locationData.tableUid}`);
    return data;
  } catch (err) {
    return null;
  }
};

export const getTips = async (locationData: any) => {
  try {
    const { data } = await $api.get(`/info/TableTipsComplexWidgetInfo?id=${locationData.tableUid}`);
    return data?.result;
  } catch (err) {
    return null;
  }
};

export const getLayoutId = async ({ headPlaceCode, placeExternalId, waiterId }) => {
  try {
    const { data } = await $cloudPayments.get(`/paymentpages/external/${777}/${193}/${13277}`);
    return data.data.paymentLink.slice(27);
  } catch (err) {
    return null;
  }
};

export const logout = async () => {
  try {
    const { data } = await $api.post(`/tools/ClearSessionKey`);
    if (!data.success) {
      setErrorPopupData('onLogout');
    }

    return data;
  } catch (err) {
    setErrorPopupData('onLogout');
    return null;
  }
};
