import { createEffect, createEvent, createStore } from 'effector';
import { createGate } from 'effector-react';
import { getLocation } from 'shared/api/endpoints';

export const getLocationFx = createEffect<any, any>(getLocation);
export const onGetLocation = createEvent<any>();

export const IndexGate = createGate<string>('IndexPage');

export const $id = createStore<any>(null);
export const $locationData = createStore<any>(null)
  .on(getLocationFx.doneData, (_, data) => data);
