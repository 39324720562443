import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import styles from './ErrorPopup.module.scss';
import Button from 'shared/ui/Button';
import FailIcon from 'assets/icons/FailIcon';
import { $errorPopupData, setErrorPopupData } from 'models/Request';
import { useUnit } from 'effector-react';
import { requestsEvents } from 'shared/ui/ErrorPopup/utils';
import Loader from 'assets/images/LoaderError.png';


const ErrorPopup = () => {
  const errorPopupData = useUnit($errorPopupData);
  const [isPendingTimeout, setTimeoutRepeat] = useState(false);

  useEffect(() => {
    const repeatTimeout = setTimeout(() => {
      setTimeoutRepeat(false);
    }, 30000);

    return () => clearTimeout(repeatTimeout);
  }, []);

  const handleOnRepeat = async () => {
    await requestsEvents[errorPopupData]();
    setTimeoutRepeat(true);
  };

  const handleClose = () => {
    setErrorPopupData('');
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {isPendingTimeout ? <img src={Loader} className={styles.loader} /> : <FailIcon />}
        <h2 className={styles.title}>Похоже, что-то пошло не так...</h2>
        <p className={styles.text}>Пожалуйста, попробуйте снова или повторите попытку позже</p>
        {/*<Button className={styles.buttonRepeat} disabled={isPendingTimeout} onClick={handleOnRepeat} text='Попробовать еще раз'*/}
        {/*        theme='highlighted' />*/}
        <Button className={styles.button} onClick={handleClose} text='Понятно' />
      </div>
    </div>
  );
};

export default ErrorPopup;
