import { h } from 'preact';
import styles from './HomePopup.module.scss';
import Menu from 'assets/images/menu.png';
import Button from 'shared/ui/Button';
import { useUnit } from 'effector-react';
import { $locationData } from 'models/Table';

const HomePopup = () => {
  const locationData = useUnit($locationData);

  return (
    <div className={styles.container}>
      <img className={styles.icon} src={Menu} />
      <p className={styles.text}>Вы можете посмотреть электронное меню или обратиться к официанту</p>
      <a className={styles.button} href={locationData?.menuUri} target='_blank'>
        <Button
          text='Посмотреть меню'
          theme='highlighted'
        />
      </a>
    </div>
  );
};

export default HomePopup;
