import { h } from 'preact';
import { memo } from 'preact/compat';
import styles from './SocialNetwork.module.scss';
import { socialNetworks } from './utils';

const SocialNetwork = ({ name }) => {

  const trackActivity = () => {
    // @ts-ignore: Unreachable code error
    ym(91804168, 'reachGoal', `GONETO_${name.toUpperCase()}`);
  };

  return (
    <a href={socialNetworks[name].url} onClick={trackActivity} target='_blank'>
      <div className={styles.container}>
        {socialNetworks[name].logo}
      </div>
    </a>
  );
};

export default memo(SocialNetwork);
