import { h } from 'preact';
import styles from './Home.module.scss';
import Logo from '../../assets/images/logo.jpg';
import Button from 'shared/ui/Button';
import { useUnit } from 'effector-react';
import { $id, $locationData } from 'models/Table';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { redirectLink } from './utils';
import useAnalytics from 'shared/hooks/useAnalytics';
import Popup from 'shared/ui/Popup';
import Sbp from '../../assets/images/sbp.png';
import { route } from 'preact-router';
import { $billData } from 'models/Order';
import { useState } from 'preact/hooks';
import Footer from 'shared/widgets/Footer';
import HomePopup from './components/HomePopup';
import HomeHeader from './components/HomeHeader';

const Home = () => {
  useAnalytics();
  const id = useUnit($id);
  const locationData = useUnit($locationData);
  const billData = useUnit($billData);
  const [isOpenedBlankOrderPopup, setOpenPopup] = useState(false);

  const openBill = () => {
    if (billData?.itemsCount === 0) {
      return setOpenPopup(true);
    }

    route(`/order?id=${id}`);
  };

  return (
    <div class={styles.container}>
      <HomeHeader />
      <Button
        className={styles.button}
        disabled={!locationData?.sbpPaymentAllowed ?? false}
        image={Sbp}
        onClick={openBill}
        text='Оплатить через'
      />
      <Button
        className={styles.button}
        disabled={!locationData?.menuUri ?? false}
        onClick={() => redirectLink(locationData?.menuUri, id, true, 'OPEN_MENU')}
        text='Посмотреть электронное меню'
      />
      <Button
        className={styles.button}
        disabled={false}
        onClick={() => redirectLink('https://coffeemania.onelink.me/5eTU/kbwjc2au', id, true, null)}
        text='Установить приложение'
      />
      <Button
        className={styles.button}
        disabled={!locationData?.inTableOrderUri ?? false}
        onClick={() => redirectLink(locationData?.inTableOrderUri, id, true, null)}
        text='Заказ на стол'
      />
      <Footer />
      {isOpenedBlankOrderPopup && (
        <Popup content={<HomePopup />} title='Вы еще ничего не заказали' setOpenPopup={setOpenPopup} />
      )}
    </div>
  );
};

export default Home;
