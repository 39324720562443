import { h } from 'preact';

const VkIcon = () => {
  return (
    <svg width='31' height='18' viewBox='0 0 31 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M27.2737 12.5948C26.5828 11.6768 25.4793 10.6303 25.0936 10.1529C24.5822 9.60214 24.7347 9.30839 25.0936 8.73005C25.0936 8.73005 28.9694 3.1303 29.3461 1.29432C29.4718 0.633363 29.1936 0.376325 28.5297 0.376325H25.1652C24.9 0.336433 24.6293 0.39134 24.3987 0.531796C24.1683 0.672253 23.9923 0.889675 23.9002 1.14744C22.8198 3.4481 21.4701 5.6059 19.8809 7.57338C19.1631 8.38122 18.8311 8.61989 18.4364 8.61989C18.0417 8.61989 18.0326 8.35367 18.0326 7.62846V1.20252C18.0326 0.284526 17.8263 0.376325 17.1355 0.376325H11.7524C11.3397 0.376325 11.2589 0.458944 11.2589 0.789422C11.2589 1.63398 12.6944 1.65234 12.6944 3.93814V8.63825C12.6944 9.55624 12.4701 10.0336 12.0305 10.0336C10.8731 10.0336 7.9483 6.04951 6.40514 1.42284C6.01935 0.477304 5.72327 0.376325 4.853 0.376325H1.47959C0.995105 0.376325 0.63623 0.550744 0.63623 1.05564C0.63623 1.97363 1.68594 6.06787 5.82196 11.7686C8.60324 15.6058 12.2458 17.6805 15.5385 17.6805C17.5392 17.6805 18.0326 17.3409 18.0326 16.4688V13.5311C18.0326 12.7968 18.3108 12.5122 18.7235 12.5122C19.1362 12.5122 19.9975 12.6958 21.9085 14.6235C24.1694 16.8267 24.322 17.8182 25.5511 17.8182H29.3461C29.732 17.8182 30.0908 17.5703 30.0908 16.9002C30.0729 16.0465 28.9694 14.4308 27.2737 12.5948Z'
        fill='#EC4C41' />
    </svg>
  );
};

export default VkIcon;
