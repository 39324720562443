import { h } from 'preact';
import styles from './Header.module.scss';
import ArrowLeftIconHeader from 'assets/icons/ArrowLeftIconHeader';
import { memo } from 'preact/compat';
import { route } from 'preact-router';
import AuthBox from 'shared/widgets/Header/components/AuthBox';

const Header = ({ isBoxVisible = false, backUrl, title = '' }) => {
  return (
    <header class={styles.container}>
      {backUrl && (
        <div className={styles.button} onClick={() => route(backUrl)}>
          <ArrowLeftIconHeader />
          <p class={styles.text}>Назад</p>
        </div>
      )}
      {title && <p className={styles.title}>{title}</p>}
      {isBoxVisible && <AuthBox />}
    </header>
  );
};

export default memo(Header);
