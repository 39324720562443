import { h, FunctionComponent } from 'preact';
import styles from './Input.module.scss';
import { InputProps } from './interfaces';

const Input: FunctionComponent<InputProps> = ({
                                                className = '',
                                                children,
                                                icon = <></>,
                                                inputMode = 'text',
                                                onChange,
                                                onKeyDown,
                                                onKeyUp,
                                                placeholder,
                                                refProp,
                                                type = 'text',
                                                value,
                                              }) => {
  return (
    <div className={styles.container}>
      <input
        className={`${styles.input}${className ? ' ' + className : ''}`}
        inputMode={inputMode}
        onInput={onChange}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        placeholder={placeholder}
        ref={refProp}
        type={type}
        value={value}
      />
      {icon}
      {children}
    </div>
  );
};

export default Input;
