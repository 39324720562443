import { useEffect, useState } from 'preact/hooks';
import { $banks } from 'models/Banks';
import { useUnit } from 'effector-react';

const useBanks = () => {
  const [isLastUsedBankExists, setLastUsedBank] = useState('');
  const banks = useUnit($banks);

  useEffect(() => {
    const lastBankId = localStorage.getItem('lastBankId');
    if (lastBankId && banks.length > 0) {
      setLastUsedBank(lastBankId);
    }
  }, [banks.length]);

  return { isLastUsedBankExists };
};

export default useBanks;
