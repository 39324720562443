import { h } from 'preact';
import styles from './InstallApp.module.css';
import Logo from 'assets/images/logo_app.png';
import Button from 'shared/ui/Button';
import { useGate } from 'effector-react';
import { InstallAppGate } from 'models/Payment';
import { route } from 'preact-router';
import { memo } from 'preact/compat';
import Star from 'assets/icons/Star';

const InstallApp = () => {
  useGate(InstallAppGate);
  const stars = [1, 2, 3, 4, 5];

  return (
    <div className={styles.container}>
      <img className={styles.logo} src={Logo} />
      <div className={styles.content}>
        <p className={styles.title}>Скачайте наше приложение</p>
        <div className={styles.ratingContainer}>
          <p className={styles.ratingValue}>4.7</p>
          {stars.map(() => (
            <Star />
          ))}
        </div>
      </div>
      <a className={styles.buttonContainer} href='https://coffeemania.onelink.me/O2jA/sbp' target='_blank' rel="noopener noreferrer">
        <Button className={styles.button} text='Скачать' theme='highlighted' />
      </a>
    </div>
  );
};

export default memo(InstallApp);
