import { useEffect } from 'preact/hooks';
import { $phone, fetchOrder, paySBP, resetData, setConfirmPopupVisibility, setPhone } from 'models/Order';
import { route } from 'preact-router';
import { useUnit } from 'effector-react';
import { $isWorkingSendTimeout, $userScores, onSendCode } from 'models/User';
import { $id } from 'models/Table';

const useOrder = (setContinueButtonClick) => {
  const phone = useUnit($phone);
  const id = useUnit($id);
  const userScores = useUnit($userScores);
  const isValidPhone = parseInt(phone.replace(/[^0-9]/g, '')).toString().length === 11;
  const accuralPoints = userScores?.maxAccuralAmount ?? 0;
  const isWorkingSendTimeout = useUnit($isWorkingSendTimeout);

  const handleSendCode = () => {
    isWorkingSendTimeout ? setConfirmPopupVisibility(true) : onSendCode();
  };

  const onVisibilityChange = () => {
    if (document.hidden) {
      resetData();
    } else {
      fetchOrder();
    }
  };

  useEffect(() => {
    fetchOrder();

    document.addEventListener('visibilitychange', onVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };

  }, []);

  const handlePay = () => {
    paySBP();
    setContinueButtonClick(true);
    route(`/banks?id=${id}`);
  };

  const handlePhoneInput = ({ target: { value } }) => setPhone(value);

  return {
    handlePhoneInput,
    handlePay,
    handleSendCode,
    accuralPoints,
    isValidPhone,
  };
};

export default useOrder;
