import { sample } from 'effector';
import { $allBanks, $banks, $query, BanksGate, getBanksFx, onChooseBank, onGetBanks } from '.';
import { queryBanks } from '../../routes/banks/utils';
import { getBanksWithLastUsedBank, redirectToPayPage, setLastUsedBank } from './utils';
import { $paymentStatus } from 'models/Payment';
import { $id } from 'models/Table';
import { reset } from 'patronum';
import { OrderGate } from 'models/Order';

sample({
  clock: [OrderGate.open, onGetBanks],
  target: getBanksFx,
});

sample({
  clock: BanksGate.open,
  source: $allBanks,
  filter: (banks) => banks.length === 0,
  target: getBanksFx,
});

sample({
  clock: $query,
  source: $allBanks,
  fn: queryBanks,
  target: $banks,
});

sample({
  clock: onChooseBank,
  filter: (bankId) => bankId !== '0',
  fn: setLastUsedBank,
});

sample({
  clock: getBanksFx.doneData,
  filter: (banks) => !!banks,
  fn: getBanksWithLastUsedBank,
  target: $banks,
});

sample({
  clock: $paymentStatus,
  source: $id,
  filter: (_, data) => data?.success,
  fn: redirectToPayPage,
});

reset({
  clock: BanksGate.close,
  target: $query,
});
