import { h, FunctionComponent } from 'preact';

const LogoutIcon = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none'
         xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.40618 16C6.17285 15.9167 5.92285 15.8658 5.70952 15.7471C5.03702 15.3721 4.6791 14.7842 4.66785 14.0154C4.65243 12.9117 4.6616 11.8071 4.66493 10.7029C4.66493 10.2904 4.9566 9.99458 5.34285 10.0004C5.72077 10.0054 5.99827 10.3017 5.9991 10.7088C5.9991 11.7818 5.9991 12.8547 5.9991 13.9275C5.9991 14.3979 6.26285 14.6646 6.73285 14.665C9.13368 14.665 11.5347 14.665 13.9358 14.665C14.3983 14.665 14.6624 14.4008 14.6629 13.9383C14.6629 9.98028 14.6629 6.02194 14.6629 2.06333C14.6629 1.60042 14.3991 1.3375 13.9358 1.33708C11.5349 1.33708 9.13396 1.33708 6.73285 1.33708C6.26243 1.33708 5.99952 1.60333 5.9991 2.07417C5.9991 3.1525 6.00118 4.23042 5.9991 5.30833C5.9991 5.79417 5.5566 6.11667 5.11327 5.96333C4.82952 5.865 4.66493 5.61292 4.66452 5.26625C4.66452 4.33917 4.65827 3.41208 4.66702 2.485C4.67035 2.15375 4.65827 1.80875 4.74202 1.49375C4.95493 0.687083 5.50243 0.202083 6.32077 0.0308333C6.34988 0.0224366 6.37842 0.0121367 6.40618 0L14.2499 0C14.2779 0.0118912 14.3067 0.0216414 14.3362 0.0291667C14.9458 0.140417 15.4195 0.457917 15.7199 0.9975C15.8487 1.22875 15.9087 1.4975 15.9999 1.75V14.25C15.9887 14.2726 15.9794 14.2962 15.9724 14.3204C15.8249 15.1475 15.2408 15.7658 14.4278 15.9538C14.3683 15.9675 14.3091 15.9846 14.2499 16H6.40618Z'
        fill='#376F62' />
      <path
        d='M0 7.875C0.065 7.66417 0.208333 7.51167 0.361667 7.36084C0.972083 6.75584 1.5775 6.14667 2.18583 5.54C2.38542 5.34084 2.625 5.28417 2.88875 5.37334C3.1525 5.4625 3.29458 5.6575 3.32708 5.92917C3.33983 6.03089 3.32815 6.13418 3.293 6.23048C3.25786 6.32678 3.20027 6.41332 3.125 6.48292C2.88639 6.72292 2.64722 6.96236 2.4075 7.20125C2.37292 7.23625 2.34083 7.27417 2.28458 7.33584H2.5C5.18167 7.33584 7.86306 7.33584 10.5442 7.33584C10.6634 7.33131 10.7826 7.34537 10.8975 7.3775C11.0399 7.42704 11.1605 7.52456 11.2388 7.65335C11.3171 7.78214 11.3482 7.93416 11.3267 8.08334C11.2783 8.41209 11.0325 8.64417 10.7096 8.66292C10.6421 8.66709 10.5742 8.66584 10.5067 8.66584H2.29833C2.33583 8.71375 2.35583 8.74584 2.38167 8.7725C2.62792 9.02 2.87625 9.26459 3.12083 9.51334C3.40667 9.805 3.41708 10.2046 3.15 10.4767C2.88292 10.7488 2.46917 10.7425 2.17583 10.4504C1.53 9.80792 0.884167 9.16584 0.245833 8.515C0.14 8.40667 0.0804167 8.25542 0 8.125V7.875Z'
        fill='#376F62' />
    </svg>
  );
};

export default LogoutIcon;
