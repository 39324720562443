import { createEffect, createEvent, createStore } from 'effector';
import { getBanks } from 'shared/api/endpoints';
import { createGate } from 'effector-react';

export const getBanksFx = createEffect<any, any>(getBanks);

export const onGetBanks = createEvent<any>();
export const onChooseBank = createEvent<string>();
export const setForbidGoBack = createEvent<any>();
export const setQuery = createEvent<any>();

export const BanksGate = createGate<void>('BanksPage');

export const $allBanks = createStore<any[]>([])
  .on(getBanksFx.doneData, (_, data) => data ?? []);
export const $banks = createStore<any[]>([]);
export const $forbidGoBack = createStore<boolean>(false)
  .on(setForbidGoBack, (_, data) => true);
export const $query = createStore<string>('')
  .on(setQuery, (_, data) => data.target.value);
