import { h } from 'preact';
import styles from './Tips.module.scss';
import Button from 'shared/ui/Button';
import UnpaidSurok from 'assets/images/unpaidSurok.png';
import PaidSurok from 'assets/images/paidSurok.png';
import { useEffect, useState } from 'preact/hooks';
import SmallSuccessIcon from 'assets/icons/SmallSuccessIcon';
import { route } from 'preact-router';
import { useGate, useUnit } from 'effector-react';
import { $id } from 'models/Table';
import { $layoutId, $tips, TipsGate } from 'models/Payment';
import InstallApp from '../InstallApp';
import Footer from 'shared/widgets/Footer';

const Tips = () => {
  useGate(TipsGate);
  const qrId = useUnit($id);
  const [isPaid, setPay] = useState(false);
  const tips = useUnit($tips);
  const layoutId = useUnit($layoutId);

  useEffect(() => {
    // @ts-ignore: Unreachable code error
    ym(91804168, 'reachGoal', 'SUCCESS_PAY');
  }, []);

  function pay() {
    // @ts-ignore: Unreachable code error
    const widget = new window.ctips.CloudTipsSiteWidget();
    const payload = {
      layoutid: tips.layoutId, // id страницы оплаты
      placeCode: tips.placeCode, // страница сотрудников заведения (если указан и layoutid, placeCode передаётся как query-параметр)
      amount: tips.amount, // предустановленная сумма на странице оплаты
      sum: tips.sum, // оплата в % от суммы чека
      invoiceid: tips.invoiceId, // внешний идентификатор (например номер заказа)
      hideamount: tips.hideamount, // не показывать поле ввода суммы
    };
    // onSuccess: 'https://cloudpayments.ru/', // Если в onSuccess или onFail передать строку url, будет выполнен редирект на этот url
    widget.open(payload, {
      // Действия после успешной оплаты и закрытия пользователем окна виджета
      onSuccess: (data) => {
        setPay(true);
        // @ts-ignore: Unreachable code error
        ym(91804168, 'reachGoal', 'SUCCESS_TIPS');
      },
      // Действия после неуспешной оплаты
      onFail: (data) => {
        setPay(false);
      },
    });
  }

  const onClickTips = () => {
    if (isPaid || !tips) {
      route(`/?id=${qrId}`);
    } else {
      pay();
    }
  };

  return (
    <div className={styles.container}>
      <InstallApp />
      <div className={styles.headerContainer}>
        <SmallSuccessIcon />
        <p className={styles.headerTitle}>{isPaid ? 'Чаевые оставлены!' : 'Заказ успешно оплачен!'}</p>
      </div>
      <div className={styles.tipsContainer}>
        <p className={styles.tipsTitle}>{(isPaid || !tips) ? 'Спасибо!' : 'Хотите оставить чаевые официанту?'}</p>
        {(isPaid || !tips) && <p className={styles.paidDescription}>Мы будем рады видеть вас снова.</p>}
        {!isPaid && tips && (
          <>
            <img alt='Оставить чаевые официанту' className={styles.surok} src={UnpaidSurok} />
            <p className={styles.unpaidDescription}>Если вам понравилось обслуживание, вы можете поблагодарить
              официанта:</p>
          </>
        )}
        {(isPaid || !tips) && <img alt='Спасибо!' className={styles.surok} src={PaidSurok} />}
        {/*<Button*/}
        {/*  className={styles.button}*/}
        {/*  //disabled={!locationData?.[isDisabled] ?? false}*/}
        {/*  //image={image}*/}
        {/*  onClick={onClickTips}*/}
        {/*  text={isPaid ? 'Вернуться на главную' : 'Оставить чаевые'}*/}
        {/*  theme='highlighted'*/}
        {/*/>*/}
        <Button
          className={styles.button}
          onClick={onClickTips}
          text={(isPaid || !tips) ? 'Вернуться на главную' : 'Оставить чаевые'}
          theme='highlighted'
        />
        {/*<Button*/}
        {/*  className={styles.button}*/}
        {/*  //disabled={!locationData?.[isDisabled] ?? false}*/}
        {/*  //image={image}*/}
        {/*  onClick={onClickTips}*/}
        {/*  text='Вернуться на главную'*/}
        {/*  theme='highlighted'*/}
        {/*/>*/}
        <Footer />
        {/*{!isPaid && <Button*/}
        {/*  className={styles.button}*/}
        {/*  //disabled={!locationData?.[isDisabled] ?? false}*/}
        {/*  //image={image}*/}
        {/*  onClick={() => setPay(true)}*/}
        {/*  text='Оставил!'*/}
        {/*  theme='highlighted'*/}
        {/*/>}*/}
      </div>
    </div>
  );
};

export default Tips;
