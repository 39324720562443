const Fetch = (baseUrl: string, headers: any = {}) => {
  const get = async (slug: string = '', credentials: RequestCredentials = 'include', mode: any = 'cors') => {
    const response = await fetch(baseUrl + slug, {
      credentials,
      headers: {
        ...headers,
        Authorization: '1ee9d775b6094b50a27488ae3ae8c7f8',
      },
    });
    const data = await response.json();
    return { data };
  };
  const post = async (slug: string, body: object = {}) => {
    const response = await fetch(baseUrl + slug, {
      credentials: 'include',
      method: 'POST',
      headers: {
        ...headers,
        Authorization: '1ee9d775b6094b50a27488ae3ae8c7f8',
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(body),
    });
    const data = await response.json();
    return { data };
  };
  return { get, post };
};

export default Fetch;
