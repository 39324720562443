import { h } from 'preact';
import styles from './Total.module.scss';
import { useUnit } from 'effector-react';
import { $billData, $scores } from 'models/Order';

const Total = () => {
  const billData = useUnit($billData);
  const scores = useUnit($scores);
  const raw = `${Math.floor(billData?.totalWOPoints / 100)}${billData?.totalWOPoints % 100 > 0 ? `,${billData?.totalWOPoints % 100}` : ''}`;
  const total = `${Math.floor(billData?.totalWOPoints / 100 - scores)}${billData?.totalWOPoints % 100 > 0 ? `,${billData?.totalWOPoints % 100}` : ''}`;

  return (
    <div className={styles.container}>
      <p>Итого:</p>
      <div className={styles.sums}>
        <p className={styles.total}>{total} ₽</p>
        <p className={styles.raw}>{raw} ₽</p>
      </div>

    </div>
  );
};

export default Total;
