import { createEffect, createEvent, sample } from 'effector';

export const createCountdown = ({ start = createEvent(), abort = createEvent(), $isWorking }) => {
  const tick = createEvent();
  const timerFx = createEffect(wait);

  $isWorking.on(abort, () => false).on(start, () => true);

  sample({
    clock: start,
    filter: timerFx.pending.map(is => !is),
    fn: () => 59,
    target: tick,
  });

  sample({
    clock: tick,
    target: timerFx,
  });

  const willTick = sample({
    clock: timerFx.done.map(({ params }: any) => params - 1),
    filter: seconds => seconds >= 0,
  });

  sample({
    clock: timerFx.done.map(({ params }: any) => params - 1),
    filter: seconds => seconds < 0,
    target: abort,
  });

  sample({
    clock: willTick,
    filter: $isWorking,
    target: tick,
  });

  return tick;
};

function wait() {
  return new Promise(resolve => {
    setTimeout(resolve, 1000);
  });
}
