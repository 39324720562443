import { h } from 'preact';
import styles from './PopupFooter.module.scss';
import Button from 'shared/ui/Button';
import { popupFooterButtons } from 'shared/widgets/Footer/components/PopupFooter/utils';

const PopupFooter = () => {
  return (
    <>
      <p className={styles.description}>Свяжитесь с нами или оставьте отзыв любым удобным для вас способом</p>
      {popupFooterButtons.map(({ icon, link, text }) => (
        <a className={styles.buttonContainer} href={link}>
          <Button className={styles.button} contentClassName={styles.buttonContent} icon={icon} text={text} />
        </a>
      ))}
    </>
  );
};

export default PopupFooter;
