import { h } from 'preact';

const PhoneIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9225 13.9064C18.1974 14.6149 19.4731 15.3235 20.7479 16.0321C21.2967 16.3367 21.5374 16.9833 21.3214 17.5729C20.2245 20.5696 17.0119 22.1513 13.9892 21.0481C7.79949 18.7888 3.21147 14.2006 0.952003 8.01092C-0.151284 4.98819 1.43053 1.77557 4.42715 0.678707C5.01682 0.462663 5.66334 0.703373 5.96878 1.25214C6.67656 2.52706 7.38519 3.80274 8.09367 5.07759C8.42579 5.67568 8.34773 6.38562 7.89315 6.89635C7.29785 7.56624 6.70265 8.23598 6.10735 8.90517C7.37808 11.9996 10.0004 14.622 13.0949 15.8927C13.7641 15.2974 14.4338 14.7022 15.1037 14.1069C15.6152 13.6523 16.3245 13.5742 16.9224 13.9064L16.9225 13.9064Z" fill="#EC4C41"/>
    </svg>
  );
};

export default PhoneIcon;
