import { h } from 'preact';
import styles from './AuthBox.module.scss';
import LogoutIcon from 'assets/icons/LogoutIcon';
import { useUnit } from 'effector-react';
import { $phone } from 'models/Order';
import { $authData, onLogout } from 'models/User';

const AuthBox = () => {
  const authData = useUnit($authData);
  const phone = useUnit($phone);

  if (!authData) {
    return <></>
  }

  return (
    <div className={styles.container}>
      <p className={styles.phone}>{authData || phone}</p>
      <div className={styles.icon} onClick={onLogout}>
        <LogoutIcon />
      </div>
    </div>
  );
};

export default AuthBox;
