import { h } from 'preact';
import styles from './Popup.module.scss';
import CloseIcon from 'assets/icons/CloseIcon';
import { useUnit } from 'effector-react';
import { $locationData } from 'models/Table';

const Popup = ({ content, title, setOpenPopup }) => {
  const locationData = useUnit($locationData);

  if (!locationData) return <></>;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        {content}
        <div className={styles.closeIcon} onClick={() => setOpenPopup(false)}>
          <CloseIcon className='' />
        </div>
      </div>
    </div>
  );
};

export default Popup;
