import Bank from './components/Bank';
import Input from 'shared/ui/Input';
import styles from './Banks.module.scss';
import Divider from 'shared/ui/Divider';
import Header from 'shared/widgets/Header';
import { $banks, $forbidGoBack, $query, BanksGate, onChooseBank, setQuery } from 'models/Banks';
import { useUnit } from 'effector-react';
import DefaultBank from '../../assets/images/DefaultBank.png';
import { getPaymentStatusFx } from 'models/Payment';
import useAnalytics from 'shared/hooks/useAnalytics';
import useBanks from './useBanks';
import SearchIcon from 'assets/icons/SearchIcon';
import BanksPending from '../payment/components/BanksPending';
import { $id } from 'models/Table';
import { useState } from 'preact/hooks';

const Banks = () => {
  useAnalytics();
  const { isLastUsedBankExists } = useBanks();
  // @ts-ignore
  BanksGate();
  const banks = useUnit($banks);
  const id = useUnit($id);
  const query = useUnit($query);
  const [isPaymentInited, setPaymentInit] = useState(false);
  const isPaymentStatusPending = useUnit(getPaymentStatusFx.pending);
  const forbidGoBack = useUnit($forbidGoBack);

  const handleInitPayment = (bankId) => {
    onChooseBank(bankId);
    setPaymentInit(true);
  }

  return (
    <>
      <section className={styles.container}>
        <Header backUrl={forbidGoBack ? null : `/order?id=${id}`} title='Выберите банк' />
        <Input
          icon={<SearchIcon className={styles.searchIcon} />} onChange={setQuery} placeholder='Название банка'
          value={query}
        />
        <div className={styles.banks}>
          <Bank
            className={styles.bank}
            logo={DefaultBank}
            name='Банк по умолчанию'
            onClick={() => handleInitPayment('0')}
          />
          {isLastUsedBankExists ? <p className={styles.lastUsedTitle}>Вы выбирали в прошлый раз:</p> : <Divider />}
          {isLastUsedBankExists ? (
            <>
              <Bank
                className={styles.bank}
                logo={banks[0]?.logoURL}
                name={banks[0]?.bankName}
                onClick={() => handleInitPayment(banks[0]?.schema)}
              />
              <Divider />
              {banks.slice(1).map((item) => (
                <Bank
                  className={styles.bank}
                  logo={item?.logoURL}
                  name={item?.bankName}
                  onClick={() => handleInitPayment(item?.schema)}
                />
              ))}
            </>) : banks.map((item) => (
            <Bank
              className={styles.bank}
              logo={item?.logoURL}
              name={item?.bankName}
              onClick={() => handleInitPayment(item?.schema)}
            />
          ))}
        </div>
      </section>
      {isPaymentStatusPending && isPaymentInited && <BanksPending />}
    </>
  );
};

export default Banks;
